.header {
  margin-bottom: var(--spacing-3xl);
}

.title {
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xl);
}

.name {
  font-size: 24px;
  color: var(--slate-grey);
}

.attributes {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: var(--spacing-lg);
}

.attribute {
  display: grid;
  grid-template-columns: 80px 1fr;
}

.property {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.property-icon {
  width: 16px;
  height: 16px;
}

.property-name {
  font-size: 12px;
  color: var(--grey);
}

.value {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0px;
  margin: 0px;
  gap: var(--spacing-md);
  color: var(--slate-grey);
  font-size: 12px;
}

.chip {
  background: var(--mid-grey);
  border-radius: var(--radius-lg, 10px);
  padding: var(--spacing-xxs) var(--spacing-md);
}

.divider {
  margin-bottom: var(--spacing-3xl);
  border: none;
  height: 1px;
  background-color: var(--light-grey);
}

.interactions {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: var(--spacing-3xl);
}

.interaction-info {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  font-size: 14px;
  color: var(--grey);
  font-weight: 500;
  margin-bottom: var(--spacing-md);
}

.message {
  font-size: 14px;
  color: var(--slate-grey);
}

.interaction-loading-info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: var(--spacing-md);
}

.highlight {
  background-color: #dcecfd;
  padding: var(--spacing-lg);
  border-radius: var(--spacing-md);

  span {
    color: var(--primary);
  }
}
