.choose-app {
  background: #fafcff;
  padding-top: 89px;
  min-height: 100dvh;
  font-family: 'Proxima Nova', sans-serif;
  box-sizing: border-box;

  * {
    font-family: 'Proxima Nova', sans-serif;
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}

.content {
  max-width: 90%;
  width: 694px;
  margin: 0 auto;
}

.header {
  display: grid;
  gap: 60px;
  justify-items: center;
  margin-bottom: 48px;
}

.title {
  font-size: 30px;
  font-weight: 400;
}

.options {
  display: grid;
  gap: 24px;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.option {
  width: 100%;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  background: #fff;
  padding: 24px;
  box-shadow:
    0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.log-bg {
  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border-radius: 100px;
  display: grid;
  place-items: center;
  padding: 10px;
  width: max-content;
  margin-bottom: 16px;
}

.ai-logo-bg {
  composes: log-bg;
  background: var(
    --Gradient-Linear-Color-81,
    linear-gradient(45deg, #4b73ff 0%, #7cf7ff 100%)
  );
}

.core-logo-bg {
  composes: log-bg;
  background: #171a1f;
}

.option-title {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
}

.option-description {
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
}

button {
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 14px;
  font-weight: 500;
  padding: 10px 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.ai-button {
  border: 1px solid #2589fb;
  background: #2589fb;
  color: #fff;
}

.core-button {
  border: 1px solid #d0d5dd;
  background: #fff;
  color: #344054;
}
