.reset {
  --sidebar-width: 56px;
  --drawer-transition: 270ms ease;
  --drawer-max-width: 500px;

  /* colours */
  --slate-grey: #565d6d;
  --silver: #d0d5dd;
  --light-grey: #f2f4f7;
  --mid-grey: #e5eaf5;
  --grey: #72758d;

  /* spacing */
  --spacing-xxs: 2px;
  --spacing-xs: 4px;
  --spacing-sm: 6px;
  --spacing-md: 8px;
  --spacing-lg: 12px;
  --spacing-xl: 16px;
  --spacing-3xl: 24px;
  --spacing-4xl: 32px;
  --sidebar-width: 56px;

  * {
    font-family: 'Proxima Nova', sans-serif;
    box-sizing: border-box;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}
