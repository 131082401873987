.fd-chat-overview {
  composes: reset from '../ResultsPage/AskAi/styles.css';
  padding: var(--spacing-3xl) 40px;
}

.title {
  font-size: 24px;
}

.description {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  font-size: 16px;
}

.main-content {
  display: grid;
  gap: 8px;

  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    gap: 53px;
  }
}

.column {
  display: grid;
  gap: var(--spacing-md);
  grid-auto-rows: max-content;
}

.card {
  background-color: #fff;
  padding: var(--spacing-3xl);
  border-radius: 10px;
  box-shadow: 0px 1px 6px 0px rgba(16, 24, 40, 0.1);
}

.card-title {
  font-size: 16px;
  font-weight: 700;
}

.project-cost-divider {
  border-radius: var(--spacing-xxs);
  width: 100%;
  border: 1px solid #344054;
}
