.avatar {
  background-color: var(--primary);
  border-radius: 100px;
  object-fit: cover;
  container-name: avatar;
  display: grid;
  place-items: center;
}

.initials {
  color: white;
  font-size: 80%;
  font-weight: 500;
}
