.modal {
  border: none;
  border-radius: 12px;
  padding: 24px;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.close-button {
  background-color: transparent;
  border: none;
  display: grid;
  place-items: center;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}
