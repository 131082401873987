.text {
  composes: text from '../../Card/Card.module.css';
  display: inline;
  margin-right: 6px;
}

.citations-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  gap: 6px;
}

.citation {
  background-color: #e6ebef;
  width: 16px;
  height: 16px;
  border: none;
  color: var(--slate-grey);
  border-radius: 100px;
  font-size: 10px;
  font-weight: 400;
  transform: translateY(-2px);
  cursor: pointer;
  padding: 0px;
  transition: all 0.1s ease-in-out;

  &:hover,
  &[aria-expanded='true'] {
    background-color: #dcecfd;
    color: var(--primary);
  }
}
