.timeline-card {
  composes: card from '../../../../containers/Page/FdChat/OverviewPage/FDChatOverview.module.css';
}

.timeline {
  --step-size: 24px;
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: grid;
  gap: var(--spacing-4xl);
}

.loading-container {
  display: grid;
  gap: var(--step-size);
  grid-template-columns: max-content 1fr;
}

.timeline-step {
  position: relative;
  --line-color: #eaecf0;
  --icon: url('../../../../assets/fdchat/results/timeline-to-do.svg');

  &:last-child {
    &::after {
      display: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    height: var(--step-size);
    width: var(--step-size);
    left: calc((-1 * var(--step-size)) - var(--spacing-xl));
    top: 0;
    border-radius: 100px;
    background-image: var(--icon);
    object-fit: contain;
    object-position: center;
  }

  &::after {
    content: '';
    position: absolute;
    left: calc((-1 * (var(--step-size) / 2)) - var(--spacing-xl));
    top: calc(var(--step-size) + var(--spacing-xs));
    background-color: var(--line-color);
    height: 100%;
    width: 1px;
  }
}

.timeline-step-done {
  composes: timeline-step;
  --line-color: #000;
  --icon: url('../../../../assets/fdchat/results/timeline-done.svg');
}

.timeline-step-in-progress {
  composes: timeline-step;
  --line-color: #eaecf0;
  --icon: url('../../../../assets/fdchat/results/timeline-in-progress.svg');
}

.timeline-step-to-do {
  composes: timeline-step;
}

.timeline-step-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: var(--spacing-xxs);
}

.timeline-step-description {
  font-size: 16px;
}
