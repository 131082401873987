.card {
  background-color: white;
  padding: 16px 24px;
  border-radius: 10px;
  box-shadow: 0px 1px 6px 0px rgba(16, 24, 40, 0.1);
  border: none;
  text-align: left;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.text {
  font-size: 16px;
  color: var(--slate-grey);
}
