.search {
  composes: reset from '../styles.css';
  min-width: 500px;
  transition: width var(--drawer-transition);

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.answers {
  display: grid;
  gap: 75px;
  padding-bottom: 50px;
}

.ask-ai-bar-container {
  position: fixed;
  bottom: 24px;
  z-index: 1;
  margin-top: 24px;
  min-width: 500px;
  left: var(--sidebar-width);
  display: grid;
  grid-template-columns: min(800px, 90%);
  justify-content: center;
  transition: width var(--drawer-transition);

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
