.drawer {
  composes: reset from '../../styles.css';
  background-color: white;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: var(--drawer-max-width);
  width: 100%;
  height: 100%;
  z-index: 1400;
  border-left: 1px solid var(--silver);
  box-shadow: -4px 0px 16px 4px rgba(16, 24, 40, 0.04);
  overflow-y: auto;
  transition: transform var(--drawer-transition);

  @media screen and (min-width: 500px) {
    width: var(--drawer-max-width);
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
