.methodology-card {
  composes: card from '../../../../containers/Page/FdChat/OverviewPage/FDChatOverview.module.css';
  display: grid;
  gap: var(--spacing-xl);
}

.title {
  composes: card-title from '../../../../containers/Page/FdChat/OverviewPage/FDChatOverview.module.css';
}

.accordion-summary {
  font-size: 14px;
}

.accordion-details {
  display: grid;
  gap: var(--spacing-lg);
}

.accordion-details-subheading {
  font-size: 14px;
}

.accordion-details-list {
  display: grid;
  gap: var(--spacing-sm);
}

.accordion-details-list-item {
  font-size: 14px;
}
