.title {
  font-size: 16px;
  font-weight: 700;
}

.list {
  list-style: none;
  padding: 0px;
  margin: var(--spacing-xl) 0px 0px 0px;
  display: grid;
  gap: 8px;
}

.list-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  gap: 8px;
}
