.status-card {
  composes: card from '../../../../containers/Page/FdChat/OverviewPage/FDChatOverview.module.css';
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  align-items: center;
}

.label {
  color: var(--primary);
  font-size: 16px;
  font-weight: 700;
  margin-right: auto;
}

.preview-interview-link {
  color: #000;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}
