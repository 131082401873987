.menu-bar {
  display: flex;
  padding: var(--spacing-xl);
  align-items: center;
  gap: var(--spacing-sm);
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
}

.close {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: transparent;
  padding: 0px;
  border: none;
}

.navigation-arrows {
  display: flex;
  gap: var(--spacing-sm);
}

.arrow {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  padding: 0px;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.arrow-up {
  rotate: 180deg;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.breadcrumb {
  font-size: 14px;
  color: var(--slate-grey);
}

.breadcrumb-button {
  composes: breadcrumb;
  border: none;
  background-color: transparent;
  padding: 0px;
  cursor: pointer;
}
