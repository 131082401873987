.sources-card {
  composes: card from '../Card/Card.module.css';
  cursor: pointer;
}

.header {
  composes: header from '../Card/Card.module.css';
  flex-wrap: wrap;
}

.icon {
  height: 14px;
  width: 18px;
}

.title {
  composes: title from '../Card/Card.module.css';
  color: var(--slate-grey);
  margin-right: auto !important;
}

.avatars {
  display: flex;
  align-items: center;
}

.extra-sources {
  display: grid;
  height: 24px;
  border: 1px solid var(--silver);
  background-color: var(--light-grey);
  border-radius: 100px;
  place-items: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--slate-grey);
  text-align: center;
  margin-left: -6px;
  padding: 0px 4px;
}

.summary {
  composes: text from '../Card/Card.module.css';
}
