.project-brief-card {
  composes: card from '../../../../containers/Page/FdChat/OverviewPage/FDChatOverview.module.css';
  display: grid;
  gap: var(--spacing-xl);
}

.title {
  composes: card-title from '../../../../containers/Page/FdChat/OverviewPage/FDChatOverview.module.css';
}

.brief-label {
  font-size: 14px;
  padding-bottom: var(--spacing-xs);
}

.questions {
  margin: 0px 0px 0px 1em;
  padding: 0px;
  font-size: 14px;
  display: grid;
  gap: var(--spacing-xs);
}

.no-data {
  opacity: 0.5;
}

.context {
  white-space: pre-wrap;
}
