.title {
  font-size: 24px;
  color: var(--slate-grey);
  margin-bottom: 24px !important;
}

.description {
  font-size: 16px;
  color: var(--slate-grey);
  margin-bottom: 16px;
}

.respondents {
  max-width: 100%;
  width: max-content;
  display: grid;
  gap: 16px;
}
