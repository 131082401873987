.audience-card {
  composes: card from '../../../../containers/Page/FdChat/OverviewPage/FDChatOverview.module.css';
  display: grid;
  gap: var(--spacing-xl);
}

.title {
  composes: card-title from '../../../../containers/Page/FdChat/OverviewPage/FDChatOverview.module.css';
}

.list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: grid;
  gap: var(--spacing-xl);
}

.list-item {
  font-size: 14px;
}

.label {
  font-weight: 700;
}

.audience-summary-description {
  margin-top: var(--spacing-md);
}
