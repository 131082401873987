.respondent-card {
  background-color: white;
  display: flex;
  gap: 12px;
  padding: 6px 16px;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  align-items: center;
  text-align: left;
  cursor: pointer;
}

.name {
  font-size: 14px;
  font-weight: 500;
  color: #344054;
}

.meta-data {
  font-size: 14px;
  color: #475467;
}
