.ai-assistant-card {
  composes: card from '../Card/Card.module.css';
}

.header {
  composes: header from '../Card/Card.module.css';
}

.icon {
  width: 24px;
  height: 24px;
}

.title {
  composes: title from '../Card/Card.module.css';
  color: var(--primary);
}

.summary-response,
.subpoint {
  margin-bottom: 18px;
}

.subpoint:last-child {
  margin-bottom: 0px;
}

.subpoints {
  list-style: none;
  margin: 0;
  padding: 0;
}

.loading {
  display: grid;
  gap: 18px;
}

.footer {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  margin-top: var(--spacing-md);
}

.footer-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: grid;
  place-items: center;
  padding: 0px;

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
