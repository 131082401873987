.interview-link-card {
  composes: card from '../../../../containers/Page/FdChat/OverviewPage/FDChatOverview.module.css';
  display: grid;
  gap: var(--spacing-xl);
}

.title {
  composes: card-title from '../../../../containers/Page/FdChat/OverviewPage/FDChatOverview.module.css';
}

.interview-link-group {
  display: grid;
  grid-template-columns: auto max-content;
}

.interview-link {
  background-color: var(--light-grey);
  border: none;
  font-size: 16px;
  color: #333;
  padding: 0px 1em;
}

.copy-link-button {
  background-color: #000;
  color: #fff;
  border-radius: 0px 4px 4px 0px;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.copy-link-warning {
  font-weight: 500;
}
