.title {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: var(--spacing-sm);
}

.text {
  font-weight: 400;
  font-size: 16px;
}

.categories {
  display: grid;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-md);
  margin-bottom: var(--spacing-3xl);
}

.category {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  border: 1px solid var(--silver);
  padding: 10px 14px;
  border-radius: 8px;
  cursor: pointer;

  input[type='checkbox'] {
    accent-color: black;
    height: 18px;
    width: 18px;
    border: 2px solid black;
    margin: 0px;
  }
}

.error-message {
  composes: text;
  color: red;
}

.extra-information {
  display: grid;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-3xl);

  textarea {
    border: 1px solid var(--silver);
    border-radius: 8px;
    padding: 12px 14px;
    font-size: 16px;
    font-weight: 400;
  }
}

.form-controls {
  display: flex;
  gap: var(--spacing-lg);
  justify-content: center;
}

.button {
  border-radius: 10px;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid black;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  color: var(--slate-grey);
}

.submit-button {
  composes: button;
  border: none;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  gap: var(--spacing-md);

  &:disabled {
    background-color: var(--silver);
    color: black;
    pointer-events: none;
  }
}
