* {
  font-family: 'Proxima Nova', sans-serif;
  box-sizing: border-box;
}

.page {
  background: #fafcff;
  min-height: 100dvh;
  padding: 20dvh 0px 20px;
}

.title {
  color: var(--Gray-900, #101828);
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 24px;
  text-align: center;
}

.subtitle {
  color: #475467;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 32px;
  text-align: center;
}

.input-label {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
}

.input {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
  padding: 10px 14px;
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: #667085;
  }

  &:focus {
    outline: 1px solid #2589fb;
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 0px,
      rgba(37, 137, 251, 0.4) 0px 0px 0px 3px,
      rgba(16, 24, 40, 0.05) 0px 1px 2px 0px;
  }
}
